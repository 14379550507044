/* Eliminate global margin and padding defaults */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

.App {
  text-align: center;
  /* background-image: url('./media/clouds_3.jpg');  */
  background-size: cover;
  background-attachment: fixed;
}

html, body, main, section, div#root, div.app, #app, #app>div {
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-image: url('./media/starry_background.png'); 
  background-size:cover;
  background-attachment: fixed;  
  background-position: fixed;
}

a, p, h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
}

body {
  overflow-x: hidden;
}

/* .starry-background {
  background-image: url('./media/starry_background.png'); 
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  margin:0px;
  padding: 0px;
  display: flex;
} */

.main-container {
  background-image: url('./media/clouds.4.png');
  background-position: fixed;
  background-size: cover;
  background-attachment: fixed;
  animation: wind 250s linear infinite;
  height: max-content;
  transform: translateZ(1);
  /* will-change: transform; */
}

@keyframes wind {
  0% {
    background-position: 0 300vmin;
  }
  100% {
    background-position: 800vmin;
  } 
}

/* .clouds {
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-image: url('./media/starry_background.png');
  position: relative;
  z-index: 3;
} */

.card-divider {
  width: 90%;
  height: 1px;
  align-self: center;
  text-align: center;
  background-color: #FFE81F;
}

.card-divider-sides {
  width: 90%;
  height: .5px;
  align-self: center;
  text-align: center;
  background-color: #FFE81F;
}

/* .starry-background {
  background-image: url('./media/starry_background.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */


/* .fill-window {
  height: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
} */


/* Navigation Menu Section Styling*/
/* #navBar {
  background-color: #041c3200;
  color: #ECB365;
  justify-content: space-between; 
  gap: 2rem;
  padding: 0 1rem;
  display: flexbox;
  position: fixed;
  height: 100%;
  top: 30%;
  z-index: 1;
}

/* .nav {
  justify-content: space-between;
} */

/* .siteLogo {
  display: flex;
  justify-content: flex-start;
} */
/*
.navLogo {
  display: flex;
  justify-content: flex-start;
}

.navItems {
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  
}

.navItems ul {
  padding: 10px;
  margin: 0;
  list-style: none;
  display: flex; 
  gap: 1rem;
}

.navItems a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: stretch; 
  padding: .5rem;
}

.navItems li {
  font-size: 1.5rem;
  padding: 30px;
  padding-bottom:2px;
  background-image: linear-gradient(#ECB365 0 0);
  background-position: 0 100%; OR bottom left
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition:
    background-size 0.5s,
    background-position 0s 0.3s; /*change after the size immediately
}

.navItems li:active {
  text-decoration: underline;
  background-color: #064663;
}

.navItems li:hover {
  background-position: 100% 100%; OR bottom right
  background-size: 100% 2px;
} 
*/


/* Home and about Section Styling, Project component nested in About component*/
.parentAbout {
  width: 100%;
  height: 100vh;
  color: #FFE81F;
  padding: 0px;
  flex-direction: row;
  /* align-items: stretch; */
  display: flex;
  justify-content: center;
}


/* .parentAbout > * {
  padding: 10px;
  flex: 1 100%;
} */

.aboutName {
  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  order: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  z-index: 1;
  width: 45%;
}

.aboutName-items {
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.aboutPicture {
  order: 2;
  text-align: center;
  justify-content: center;
  display: flex;
  width: 45%;
}

.aboutPicBorder:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
}

.aboutPicBorder {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 32.813rem;
  width: 23.438rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: 1s ease-in-out;
  /*animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; */
}

.aboutPicUpper {
  width: 90%;
  border-radius: 25px;
  text-align: center;
  align-self: center;
  height: 65%;
}

.aboutPicUpper_sectionOne {
  height: 15%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.aboutPicUpper_sectionOne_One {
  order: 1;
  width: 50%;
  border-radius: 15px;
  text-align: left;
  align-self: center;
}

.aboutPicUpper_sectionOne_Two {
  order: 2;
  width: 50%;
  border-radius: 15px;
  text-align: right;
  align-self: center;
}

.aboutPicUpper_sectionTwo {
  height: 20%;
}

.aboutPicUpper_sectionThree {
  display: flex;
  height: 60%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: black;
  border-radius: 25px;
}

.aboutPicUpper_sectionThree img {
  width: 95%;
  height: 95%;
  object-fit: cover;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.aboutPicLower {
  height: 30%;
  width: 90%;
  border-radius: 25px;
  text-align: center;
  align-self: center;
}

.aboutPicLower_sectionOne {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  height: 20%;
}

.aboutPicLower_sectionOne_One {
  order: 1;
  background: #000000;  
  width: 50%;
  border-radius: 15px;
  justify-content: center;
  align-self: center;
  padding: 5px;
}

.aboutPicLower_sectionOne_Two {
  order: 2;
  background: #000000;  
  width: 50%;
  border-radius: 15px;
  justify-content: center;
  align-self: center;
  padding: 5px;
}

.aboutPicLower_sectionTwo {
  width: 100%;
  border-radius: 25px;
  height: 45%;
  display: flex;
}

.aboutPicLower_sectionTwo_One {
  width: 100%;
  border-radius: 15px;
  justify-content: center;
  align-self: center;
}

.aboutPicLower_sectionThree {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 5%;
  gap: 15px;
  font-size: small;
}

.aboutPicLower_sectionThree_One {
  order: 1;
  width: 50%;
  border-radius: 15px;
  justify-content: center;
  align-self: center;
}

.aboutPicLower_sectionThree_Two {
  order: 2;
  width: 50%;
  border-radius: 15px;
  justify-content: center;
  align-self: center;}

.starz {
  background-color: #baccff;
  box-shadow: 0px 0px 25px 5px #fff;
  position: fixed;
  border-radius: 50%;
  z-index: 0;
}

/* .starz:nth-child(2) {
  animation: blink 10s infinite;
}

.starz:nth-child(1) {
  animation: blink 20s infinite;
  animation-delay: 10s;
}


.starzTwo:nth-child(2) {
  animation: blink 7s infinite;
}

.starzTwo:nth-child(1) {
  animation: blink 15s infinite;
  animation-delay: 3s;
}

@keyframes blink {
  0% {
    opacity: 90%;
  }
  50% {
    opacity: 0%;
  }
  100% {
    opacity: 90%;
  }
} */


.starzTwo {
  background-color: #ffd7ae;
  opacity: 50%;
  box-shadow: 0px 0px 25px 10px #ffffff;
  position: fixed;
  border-radius: 50%;
  z-index: 0;
}



/* Projects Section Styling*/
.projects {
  color:#FFE81F;
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.projects-heading {
  text-align: center;
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
}

.project-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.projectCards-container {
  text-align: center;
  align-self: center;
  justify-content: center;
  z-index: 1;
  display: flex; 
  flex-direction: row; 
  color:#FFE81F;
  height: 75%;
  order: 2;
}

.projectItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70%;
  order: 2;
}

.projectCards {
  text-align: center;
  align-self: center;
  justify-content: center;
  z-index: 1;
  display: flex; 
  flex-direction: row; 
  color:#FFE81F;
  order: 3;
  height: 100%;
  gap: 15px;
}

.projectCard {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 32.813rem;
  width: 23.438rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
  order: 2;
}

.projectCard:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.projectCard-border {
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.projectCard-upper {
  height: 80%;
}

.projectCard-upper-sectionOne {
  height: 10%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.projectCard-upper-sectionOne h4 {
  margin: 0px;
  justify-content: center;
  align-self: center;
}

.projectCard-upper-sectionTwo {
  display: flex;
  height: 80%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #000000;
  border-radius: 25px;
}

.projectCard-upper-sectionTwo img {
  width: 100%;
  height: 65%;
  object-fit:fill;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.projectCard-bottom {
  height: 20%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.projectCard-left {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
  order: 2;
}

.projectCard-left-border {
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.projectCard-left:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.projectCard-left-upper {
  height: 80%;
  align-self: center;
  justify-content: center;
}

.projectCard-left-upper-sectionOne {
  font-size: .6rem;
  height: 10%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.projectCard-left-upper-sectionOne h4 {
  margin: 0px;
  justify-content: center;
  align-self: center;
}

.projectCard-left-upper-sectionTwo {
  display: flex;
  height: 80%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #000000;
  border-radius: 25px;
}

.projectCard-left-upper-sectionTwo img {
  width: 100%;
  height: 65%;
  object-fit: fill;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.projectCard-left-bottom {
  height: 20%;
  font-size: .5rem;
  justify-content: center;
  align-self: center;
}

.projectCard-right {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: 1s ease-in-out;
  order: 2;
}

.projectCard-right:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.projectCard-right-border {
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.projectCard-right-upper {
  height: 80%;
  align-self: center;
  justify-content: center;
}

.projectCard-right-upper-sectionOne {
  height: 10%;
  font-size: .6rem;
  display: flex;
  justify-content: center;
  align-self: center;
}

.projectCard-right-upper-sectionOne h4 {
  margin: 0px;
  justify-content: center;
  align-self: center;
}

.projectCard-right-upper-sectionTwo {
  display: flex;
  height: 80%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #000000;
  border-radius: 25px;
}

.projectCard-right-upper-sectionTwo img {
  width: 100%;
  height: 65%;
  object-fit: fill;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.projectCard-right-bottom {
  height: 20%;
  font-size: .5rem;
  justify-content: center;
  align-self: center;
}

.projectCard-link {
  text-decoration: none;
  color:#FFE81F;
}

/* Experience Section Styling */

.experiencePage {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  color: #FFE81F;
  text-align: center;
  justify-content: center;
}

/* .experiencePage > * {
  padding: 10px;
  flex: 1 100%;
} */

.csuLocation-card-container {
  order: 1;
  text-align: center;
  justify-content: center;
  display: flex;
  width: 45%;
}

.csuLocation-card {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  height: 375px;
  width: 32.813rem;
  height: 23.438rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  z-index: 1;
  transition: 1s ease-in-out;
}

.csuLocaton-card-border {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: center;
}

.csuLocation-card-upperContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20%;
  text-align: center;
  justify-content: center;
  order: 1;
}

.csuLocation-card-upperContent-one {
  order: 1;
  align-self: center;
  text-align: left;
  justify-content: center;
  width: 30%;
}

.csuLocation-card-upperContent-two {
  order: 2;
  align-self: center;
  text-align: center;
  justify-content: center;
  width: 30%;
}

.csuLocation-card-upperContent-three {
  order: 3;
  align-self: center;
  text-align: right;
  justify-content: right;  
  width: 30%;
  display: flex;
}

.csuLocation-card-lowerContent {
  order: 3;
  font-size: 12px;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
}

.csuLocation-card-lowerContent-text {
  width: 95%;
  justify-content: center;
  text-align: center;
  align-self: center;
}

.csuLocation-card-upperContent-three-inner-container-one h4 {
  border-radius: 75px;
  display: flex;
  height: 20%;
  width: 25%;
  justify-content: center;
  align-self: center;
  text-align: center;
  padding: 5px;
 }

 .csuLocation-card-upperContent-three-inner-container-three h4 {
  border-radius: 75px;
  display: flex;
  height: 30%;
  width: 30%;
  justify-content: center;
  align-self: center;
  text-align: center;
 }

.csuLocation-card:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.csuLocation {
  order: 1;
  text-align: center;
  align-self: center;
  padding: 20px;
}

.csuImage-section {
  width: 95%;
  height: 60%;
  border-radius: 25px;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  order: 2;
}

.csuImage-section-border {
  background-color: #000000;
  border-radius: 25px;
  padding: 5px;
  justify-content: center;
  align-self: center;
}

.csuImage {
  height: 90%;
  width: 90%;
  align-self: center;
  justify-content: center;
  border-radius: 25px;
}

.experienceText-container {
  order: 2;
  text-align: center;
  align-self: center;
  justify-content: center;
  display: flex;
  z-index: 1;
  width: 45%;
}

.experienceText {
  color:#FFE81F;
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
}

/* Skills and Skills Canvas Section Styling */
#skills {
  height: 100vh;
  width: 100%;
  padding: 0px;
  text-align: center;
  /* flex-wrap: wrap; */
  display: flex;
  flex-direction: column;
  color:#FFE81F;
}


.skills-header {
  text-align: center;
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-content {
  display: flex;
  flex-direction: row;
  height: 75%;
  width: 100%;
  justify-content: center;
  align-self: center;
}


.slide-arrow {
  display: flex;
  position: relative;
  background-color:#06466300;
  color:#FFE81F;
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  border: none;
  width: 6rem;
  font-size: 6rem;
  padding: 0;
  cursor: pointer;
  transition: opacity 100ms;
  z-index: 3;
  justify-content: center;
} 

.skillCard:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.skillCard-post:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.skillCard-pre:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.skillCard-non {
  display: none;
}

.SkillCard-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.skillCards {
  text-align: center;
  align-self: center;
  justify-content: center;
  z-index: 1;
  display: flex; 
  flex-direction: row; 
  color:#FFE81F;
  order: 3;
  height: 100%;
  gap: 15px;
}

.skillCard {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 32.813rem;
  width: 23.438rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
  order: 2;
}

.skillCard-deck-left {
  display: flex;
  flex-direction: row;
  height: 16.407rem;
  width: 11.719rem;
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(6, 70, 99, 1) 0%, rgba(0, 0, 0, 1) 100%);
  border-radius: 25px;
  border-left: 1px solid #fff;
  z-index: 1;
  justify-content: center;
  align-self: center;

}

.skillCard-deck-right {
  display: flex;
  flex-direction: row;
  height: 16.407rem;
  width: 11.719rem;
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(6, 70, 99, 1) 0%, rgba(0, 0, 0, 1) 100%);
  border-radius: 25px;
  border-right: 1px solid #fff;
  z-index: 1;
  order: 5;
  justify-content: center;
  align-self: center;
}

.deck-right {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-right: 1px solid #fff;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  position: static;
  z-index: 1;
  order: 3;
}

.deck-left {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-left: 1px solid #fff;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  z-index: 1;
  order: 2;
}

.deck-left-container {
  height: 100%;
  /* display: flex;
  flex-direction: row; */
  align-self: center;
  justify-content: center;
  order: 1;
}

.deck-right-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  order: 5;
}

.deck-left-non {
  display: none;
}

.deck-right-non {
  display: none;
}

.skillCard[moveLeft='1'] {
  animation: move 4s 1;
}

@keyframes move {
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }

}

.skillCard-pre {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
  order: 2;
}

.skillCard-post {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  order: 4;
  transition: 1s ease-in-out;
}

.skillCard-pre-non {
  display: none;
}

.skillCard-post-non {
  display: none;
}

.slide-arrow {
  display: flex;
  position: relative;
  background-color:#06466300;
  color:#FFE81F;
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  border: none;
  width: 6rem;
  font-size: 6rem;
  padding: 0;
  cursor: pointer;
  transition: opacity 100ms;
  z-index: 3;
  justify-content: center;
} 

.slider-button-left {
  display: flex;
  order: 1;
  height: 100%;
  z-index: 2;
  align-items: center;
}

.slider-button-right {
  display: flex;
  order: 4;
  height: 100%;
  z-index: 2;
}

.slider-button-left-non {
  display: flex;
  order: 1;
  height: 100%;
  z-index: 2;
  align-items: center;
  opacity: 0.2;
}

.slider-button-right-non {
  display: flex;
  order: 4;
  height: 100%;
  z-index: 2;
  opacity: 0.2;
}

/* Hobbies Section Styling */
#hobbies {
  height: 100vh;
  width: 100%;
  padding: 0px;
  text-align: center;
  /* flex-wrap: wrap; */
  color:#FFE81F;
}

.hobbiesContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hobbiesCards-container {
  text-align: center;
  align-self: center;
  justify-content: center;
  z-index: 1;
  display: flex; 
  flex-direction: row; 
  color:#FFE81F;
  height: 75%;
  order: 2;
}

.hobbiesHeader {
  text-align: center;
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
}

.hobbiesCards {
  text-align: center;
  align-self: center;
  justify-content: center;
  z-index: 1;
  display: flex; 
  flex-direction: row; 
  color:#FFE81F;
  order: 3;
  height: 100%;
  gap: 15px;
}

.hobbiesCard {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 32.813rem;
  width: 23.438rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
  order: 2;
}

.hobbiesCard:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.hobbiesCard-border {
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.hobbiesCard-upper {
  height: 80%;
  /* align-self: center;
  justify-content: center; */
}

.hobbiesCard-upper-sectionOne {
  height: 10%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-upper-sectionOne h4{
  margin: 0px;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-upper-sectionTwo {
  display: flex;
  height: 80%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #000000;
  border-radius: 25px;
}

.hobbiesCard-upper-sectionTwo img {
  width: 90%;
  height: 95%;
  object-fit: cover;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.hobbiesCard-bottom {
  height: 20%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.hobbiesCard-left {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
  order: 2;
}

.hobbiesCard-left:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.hobbiesCard-left-border {
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.hobbiesCard-left-upper {
  height: 80%;
  align-self: center;
  justify-content: center;
}

.hobbiesCard-left-upper-sectionOne {
  font-size: .6rem;
  height: 10%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-left-upper-sectionOne h4 {
  margin: 0px;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-left-upper-sectionTwo {
  display: flex;
  height: 80%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #000000;
  border-radius: 25px;
}

.hobbiesCard-left-upper-sectionTwo img {
  width: 90%;
  height: 95%;
  object-fit: cover;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.hobbiesCard-left-bottom {
  height: 20%;
  font-size: .5rem;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-right {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 16.407rem;
  width: 11.719rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: 1s ease-in-out;
  order: 2;
}

.hobbiesCard-right:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.hobbiesCard-right-border {
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.hobbiesCard-right-upper {
  height: 80%;
  align-self: center;
  justify-content: center;
}

.hobbiesCard-right-upper-sectionOne {
  height: 10%;
  font-size: .6rem;
  display: flex;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-right-upper-sectionOne h4 {
  margin: 0px;
  justify-content: center;
  align-self: center;
}

.hobbiesCard-right-upper-sectionTwo {
  display: flex;
  height: 80%;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #000000;
  border-radius: 25px;
}

.hobbiesCard-right-upper-sectionTwo img {
  width: 90%;
  height: 95%;
  object-fit: cover;
  justify-content: center;
  align-self: center;
  object-position: 15% 10%;
  border-radius: 15px;
}

.hobbiesCard-right-bottom {
  height: 20%;
  font-size: .5rem;
  justify-content: center;
  align-self: center;
}

/* Contact Section Styling */
.contactPage {
  height: 100vh;
  width: 100%;
  color: #FFE81F;
  padding: 0px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.contact-card-container {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
  height: 32.813rem;
  width: 23.438rem;
  border: #064663;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: 1s ease-in-out;
}

.contact-card-container:hover {
  box-shadow: 0px 0px 50px 7px #ffffff;
  transition: 1s;
}

.contact-download-resume-button {
  color: #FFE81F;
  padding: 10px;
  border-radius: 15px;
  background: radial-gradient(circle, rgba(6,70,99,1) 0%, rgba(0,0,0,1) 100%);  
}

/*footer yo*/
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 20vh;
  color: #FFE81F;
  font-size: 20px;
  z-index: 2;
}

.footer-items-left {
  width: 100%;
  text-align: center;
  justify-content: center;
  color:#FFE81F;
  z-index: 2;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.footer-items-left a {
  color:#FFE81F;
  text-decoration: none;
}

.footer-items-right {
  order: 2;
  width: 50%;
  text-align: center;
  justify-content: center;
}


/* ------------------Responsive------------------ */

@media only screen and (max-width: 950px) {
  .mouseTrail {
    display: none;
    visibility: hidden;
  }

  .parentAbout {
    flex-direction: column;
  }

  .aboutName {
    width: 100%;
    height: 20%;
  }

  .aboutPicture {
    width: 100%;
    height: 70%;
  }

  .experiencePage {
    flex-direction: column;
  }

  .csuLocation-card-container {
    width: 100%;
    height: 60%;
  }

  .experienceText-container {
    width: 75%;
    height: 30%;
  }

  .csuImage-section {
    width: 90%;
    height: 55%;
    padding: 5px;
    border-radius: 25px;
    align-self: center;
    justify-content: center;
    display: flex;
  }

  .csuLocation-card-lowerContent {
    font-size: 11px;
  }

  .csuImage-section-border {
    display: flex;
    height: 90%;
    width: 95%;
  }

  .csuImage {
    object-fit: cover;
    justify-content: center;
    align-self: center;
  }

  .skillCard-post {
    display: none;
  }

  .skillCard-pre {
    display: none;
  }

  .hobbiesCard-left {
    display: none;
  }

  .hobbiesCard-right {
    display: none;
  }

  .projectCard-right {
    display: none;
  }

  .projectCard-left {
    display: none;
  }

  .footer-items-left {
    flex-direction: column;
    height: 100vh;
    gap: .5rem;
  }

  .footer {
    flex-direction: column;
    height: 100vh;
  }

  .slide-arrow {
    width: 3rem;
    font-size: 6rem;
  }
}

@media only screen and (max-width: 950px) {
  .aboutPicBorder {
    height: 24.61rem;
    width: 17.58rem;
  }

  .skillCard {
    height: 24.61rem;
    width: 17.58rem;
  }

  .projectCard {
    height: 24.61rem;
    width: 17.58rem;
  }

  .hobbiesCard {
    height: 24.61rem;
    width: 17.58rem;
  }

  .contact-card-container {
      height: 24.61rem;
      width: 17.58rem;
    }

    .csuLocation-card {
      width: 24.61rem;
      height: 17.58rem;
      padding: 0px;
      margin: 0;
    }

    .csuLocation-card-upperContent {
      font-size: small;
    }

    .aboutPicture {
      font-size: small;
    }

    .projectCard-upper {
      height: 70%;
    }
  }

  @media only screen and (max-height: 655px) {
    #about {
      height: 650px;
    }

    #experience {
      height: 650px;
    }

    #skills {
      height: 650px;
    }

    #projects {
      height: 650px;
    }

    #hobbies {
      height: 650px;
    }

    #contact {
      height: 650px;
    }

    /* .footer {
      height: 650px;
    } */
  }
